import React, { Component } from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'

const ContactUs = class extends Component {
  constructor(props) {
    super(props)

    this.letterWidth = 12
  }

  InformationHandle = (event) => {
    const 
        additionalInformation = document.querySelector('textarea.additional')
      , target = event.target;

    target.style.width = `${Math.max(target.value.length, 5) * this.letterWidth}px`;

    if (target.value === "do")
      additionalInformation.classList.add('visible')
    else
      additionalInformation.classList.remove('visible')
  }

  KeypressHandle = (event) => {
    const target = event.target;

    target.style.width = `${Math.max(target.value.length, 5) * this.letterWidth}px`;
  }

  SelectHandle = (event) => {
    const target = event.target;

    target.style.width = `${Math.max(target.value.length, 8) * this.letterWidth}px`;
  }

  render() {
    return (
      <Layout>
        <Helmet title="Contact | Rudd Joinery">
          <meta name="description" content="Contact Rudd Joinery by phone, email or visit us and view our showroom" />
        </Helmet>
        <div className="section">
          <div className="wrapper">
            <div className="text-block story">
              <h1>
                Contact us
              </h1>
            </div>
          </div>
        </div>
        <div className="contact-us cf">
          <div className="phone">
            <div className="wrap">
              <h2>
                By phone
              </h2>
              <p>
                01485 529 136
              </p>
            </div>
          </div>
          <div className="email">
            <div className="wrap">
              <h2>
                By email
              </h2>
              <p>
                <a href="mailto:enquiries@ruddjoinery.co.uk">enquiries@ruddjoinery.co.uk</a>
              </p>
            </div>
          </div>
          <div className="write">
            <div className="wrap">
              <h2>
                By post
              </h2>
              <p>
                Rudd Joinery Limited<br />
                Sculthorpe Boulevard<br />
                Tattersett Business Park<br />
                Fakenham<br />
                Norfolk<br />
                NR21 7RL
              </p>
            </div>
          </div>
        </div>
        <div className="section" id="contact-us">
          <div className="wrapper">
            <div className="text-block story">
              <h2>Let's chat</h2>
              <p>Fill this out so we can learn more about your requirements</p>
              <form name="Enquiry" method="post" className="lets-talk" netlify-honeypot="bot-field" data-netlify="true">
                <input type="hidden" name="form-name" value="Enquiry" />
                <p>
                  Hello, my name is <input name="Name" type="text" required maxLength="100" onKeyPress={this.KeypressHandle} />, I live in <input name="Location" type="text" required onKeyPress={this.KeypressHandle} maxLength="100" /> and I am interested
                  in <select name="Interest" required onChange={this.SelectHandle}><option disabled selected></option><option>Windows</option><option>Doors</option><option>Staircases</option><option>Other</option></select>.<br/> You can contact me by email on <input name="Email" type="email" required maxLength="100" onKeyPress={this.KeypressHandle} /> or
                  by telephone on <input name="Telephone" type="tel" required maxLength="30" onKeyPress={this.KeypressHandle} />.
                </p>                
                <p>
                  I <select required onChange={ this.InformationHandle }><option disabled selected></option><option>do</option><option>do not</option></select> have additional information.
                </p>
                <textarea name="Additional" className="additional" maxlength="500">
                  
                </textarea>
                <p style={{display: 'none' }}>
                  2 + 2 = <input name="bot-field" />
                </p>
                <p>
                  <button>Submit</button>
                </p>
              </form>
            </div>
          </div>
        </div>
        <div className="section lightgrey norfolk">
          <div className="wrapper">
            <div className="text-block story">
              <h2>Our showroom</h2>
              <p>
                Our dedicated showroom is on Tattersett Business Park, near Fakenham
                in Norfolk, we advise customers to call and book an appointment
                before their visit and please note, if you're using a sat nav to
                reach us, use the postcode <strong>NR21 7RL</strong>.
              </p>
              <p>Our Showroom Opening Times:</p>
              <p>
                Monday to Friday: 9:00am - 4:30pm
                <br />
                Saturday: 9.00am - 11.30am (by appointment only)
              </p>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default ContactUs
